import { Route, Routes } from "react-router-dom";
import {
    PUBLIC_CALENDAR,
    PUBLIC_HOME_PAGE,
    PUBLIC_LEADERBOARDS,
    PUBLIC_PRICE_LIST,
    PUBLIC_SIGN_IN,
    PUBLIC_VOUCHER,
    PUBLIC_GALLERY,
    PUBLIC_ESHOP_TEST // Import the new path
} from "../../../constants/paths";
import HomePageRoute from "./pages/HomePageRoute";
import PriceListRoute from "./pages/PriceListRoute";
import SignInRoute from "./pages/SignInRoute";
import LeaderboardsRoute from "./pages/LeaderboardsRoute";
import CalendarRoute from "./pages/CalendarRoute";
import VoucherRoute from "./pages/VoucherRoute";
import GalleryRoute from "./pages/GalleryRoute";
import EshopTest from "./pages/EshopTestRoute"; // Import the new page component

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path={PUBLIC_HOME_PAGE} element={<HomePageRoute />} />
            <Route path={PUBLIC_PRICE_LIST} element={<PriceListRoute />} />
            <Route path={PUBLIC_SIGN_IN} element={<SignInRoute />} />
            <Route path={PUBLIC_LEADERBOARDS} element={<LeaderboardsRoute />} />
            <Route path={PUBLIC_CALENDAR} element={<CalendarRoute />} />
            <Route path={PUBLIC_VOUCHER} element={<VoucherRoute />} />
            <Route path={PUBLIC_GALLERY} element={<GalleryRoute />} />
            <Route path={PUBLIC_ESHOP_TEST} element={<EshopTest />} /> {/* Add the hidden route */}
        </Routes>
    );
}

export default PublicRoutes;
