import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const EshopTest = lazy(() => import("../../../pages/public/EshopTest"));

const EshopTestRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-12" />}>
            <EshopTest />
        </Suspense>
    )
}

export default EshopTestRoute;