import React from 'react';
import './App.css';
import AppRoot from "./app/AppRoot";
import Header from "./app/blocks/menu/Header";
import { useLocation } from "react-router-dom";

function App() {
    const location = useLocation();

    // Define pages where the layout should be applied
    const useLayout = location.pathname !== "/gallery";

    return (
        <>
            <Header />
            {//useLayout ? (
                <div className="layout">
                    <AppRoot />
                </div>
            //) : (

            //)
            }
        </>
    );
}

export default App;
