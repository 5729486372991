import {lazy, Suspense} from "react";
import Spinner from "../../../components/spinner/Spinner";

const GalleryPage = lazy(() => import("../../../pages/public/GalleryPage"));

const GalleryRoute = () => {
    return (
        <Suspense fallback={
            <Spinner className="g-col-4" />
        }>
            <GalleryPage />
        </Suspense>
    );
};

export default GalleryRoute;
